<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo_transparent.png">
  </div>
</template>

<script>

export default {
  name: 'App',
  methods: {
    handleScroll () {
      let CHANGE = this.direction + 2;

      this.r1 += CHANGE
      this.r2 += CHANGE
      this.r3 += CHANGE * 1.2;
      this.r4 -= CHANGE
      this.r5 -= CHANGE
      this.r6 += CHANGE
      this.r7 -= CHANGE
      this.r8 -= CHANGE
      this.r9 += CHANGE
      this.setBackground()
      this.scrollPos = window.scrollY

      if (window.scrollY % 250 < 100) {
        this.direction = this.direction * -1
      }
    },

    setBackground () {
      document.body.style.setProperty('background', 'rgb(' + this.val1 +')');
      document.body.style.setProperty('background', `linear-gradient(90deg, rgba(${this.val1},1) 0%, rgba(${this.val2},1) 0%, rgba(${this.val3},1) 100%)`);
    }
  },

  computed: {
    val1 () {
      return `${this.r1},${this.r2},${this.r3}`
    },

    val2 () {
      return `${this.r4},${this.r5},${this.r6}`
    },

    val3 () {
      return `${this.r7},${this.r8},${this.r9}`
    },
  },

  watch: {
    r1: 'setBackground',
  },

  mounted () {
    window.addEventListener('scroll', this.handleScroll);
    this.setBackground()
  },

  data () {
    return {
      scrollPos: 0,
      r1: 2,
      r2: 0,
      r3: 36,
      r4: 255,
      r5: 220,
      r6: 0,
      r7: 0,
      r8: 212,
      r9: 255,
      direction: 1
    }
  }
}
</script>

<style>
body {
  height: 10000px;
}

img {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
